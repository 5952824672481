import { axiosInstance } from 'app/constants/axios-instance';
import { Login } from 'app/models/login';
import { Customer } from 'app/models/customer';
import { PasswordResetRequest } from 'app/models/password-reset-request';
import { PasswordReset } from 'app/models/password-reset';
import { Config } from 'app/models/config';

export const login = (data: Login) =>
  axiosInstance
    .post<void>('/public/login', data)
    .then((response) => response.data);

export const logout = () =>
  axiosInstance.post<void>('/public/logout').then((response) => response.data);

export const getCurrentCustomerDetails = () =>
  axiosInstance
    .get<Customer>('/public/current')
    .then((response) => response.data);

export const getConfig = () =>
  axiosInstance.get<Config>('/public/config').then((response) => response.data);

export const requestPasswordReset = (
  passwordResetRequest: PasswordResetRequest
) =>
  axiosInstance
    .post<void>('/public/password-reset/request', passwordResetRequest)
    .then((response) => response.data);

export const confirmPasswordReset = (passwordReset: PasswordReset) =>
  axiosInstance
    .post<void>('/public/password-reset/confirm', passwordReset)
    .then((response) => response.data);
