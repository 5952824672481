import React from 'react';
import { Button, Modal } from 'antd';
import { Trans } from 'react-i18next';
import Cookies from 'js-cookie';

import { Config } from 'app/models/config';
import { useTranslation } from 'app/i18n-config';

interface Props {
  config: Config | null;
}

const COOKIE_TERMS_ACCEPTED = 'TERMS_ACCEPTED';

export const isTermsAccepted = (config: Config | null) => {
  return Cookies.get(COOKIE_TERMS_ACCEPTED) === config?.termsUrl;
};

export const acceptTerms = (config: Config | null) => {
  if (!config?.termsUrl) {
    return;
  }

  Cookies.set(COOKIE_TERMS_ACCEPTED, config.termsUrl);
};

export const Terms: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation();
  const [showTerms, setShowTerms] = React.useState<boolean>(false);

  const handleClickAccept = React.useCallback(() => {
    acceptTerms(config);
    setShowTerms(false);
  }, [config]);

  React.useEffect(() => {
    const accepted = isTermsAccepted(config);

    setShowTerms(!!config?.termsUrl && !accepted);
  }, [config]);

  if (!config?.termsUrl) {
    return null;
  }

  return (
    <Modal
      open={showTerms}
      centered={true}
      closable={false}
      title={t('terms.title')}
      footer={[
        <Button key="agree" type="primary" onClick={handleClickAccept}>
          {t('terms.agreeButton')}
        </Button>,
      ]}
    >
      <Trans
        i18nKey="terms.description"
        components={{
          terms: (
            <a href={config.termsUrl} target="_blank" rel="noreferrer">
              terms
            </a>
          ),
        }}
      />
    </Modal>
  );
};
