import React from 'react';
import { Dropdown, Input, Menu, Spin } from 'antd';
import { CaretDownFilled, UserOutlined } from '@ant-design/icons';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'app/i18n-config';
import classNames from 'classnames';

import { PATH } from 'app/constants/path';
import { generatePathWithQuery } from 'app/utils/generate-path-with-query';
import { Customer } from 'app/models/customer';
import { handleError } from 'app/utils/handle-error';
import { RoleType } from 'app/models/role-type';

import styles from './main-header.module.scss';

import logoImage from './logo.svg';

interface Props {
  customer: Customer;
  onLogOut: () => Promise<void>;
}

export const MainHeader: React.FC<Props> = ({ customer, onLogOut }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBeingLoggedOut, setIsBeingLoggedOut] = React.useState(false);
  const isAdmin = customer.roles.includes(RoleType.ADMIN);

  const handleSearch = React.useCallback(
    (value: string) => {
      navigate(
        generatePathWithQuery(PATH.SEARCH_RESULTS_PAGE, undefined, {
          query: value,
        })
      );
    },
    [navigate]
  );

  const handleLogout = React.useCallback(() => {
    setIsBeingLoggedOut(true);
    onLogOut().catch(handleError);
  }, [onLogOut]);

  const userMenuNode = (
    <Menu>
      <Menu.Item key="profile">
        <Link to={generatePath(PATH.PROFILE_PAGE)}>
          {t('header.user.profile')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={handleLogout}>
        <Spin spinning={isBeingLoggedOut}>{t('header.user.logout')}</Spin>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.mainHeader}>
      <img src={logoImage} alt="logo" className={styles.logo} />
      <nav className={styles.nav}>
        <Link to={generatePath(PATH.NEWS_PAGE)} className={styles.navItem}>
          {t('header.menu.news')}
        </Link>
        <Link to={generatePath(PATH.DOWNLOADS_PAGE)} className={styles.navItem}>
          {t('header.menu.downloads')}
        </Link>
        <Link
          to={generatePath(PATH.QUOTATIONS_PAGE)}
          className={styles.navItem}
        >
          {t('header.menu.quotations')}
        </Link>
        <Link to={generatePath(PATH.BASKET_PAGE)} className={styles.navItem}>
          {t('header.menu.basket')}
        </Link>
        <Link to={generatePath(PATH.ORDERS_PAGE)} className={styles.navItem}>
          {t('header.menu.orders')}
        </Link>
        <Link to={generatePath(PATH.INVOICES_PAGE)} className={styles.navItem}>
          {t('header.menu.invoices')}
        </Link>
        {isAdmin && (
          <Link
            to={generatePath(PATH.CUSTOMER_LIST_PAGE)}
            className={styles.navItem}
          >
            {t('header.menu.admin')}
          </Link>
        )}
      </nav>
      <div className={styles.search}>
        <Input.Search onSearch={handleSearch} />
      </div>
      <div className={styles.userWrapper}>
        <Dropdown overlay={userMenuNode} trigger={['click']}>
          <div className={styles.userBlock}>
            <div className={styles.userInfo}>
              <div>
                {customer.fullName}, {customer.companyName}
              </div>
              <div>
                {t('header.user.creditLimit', {
                  limit: customer.creditLimit,
                  currency: customer.currency,
                })}{' '}
                <span
                  className={classNames(
                    customer.isNegativeBalance && styles.negativeBalance
                  )}
                >
                  (
                  {t('header.user.balance', {
                    balance: customer.balance,
                    currency: customer.currency,
                  })}
                  )
                </span>
              </div>
            </div>
            <UserOutlined className={styles.userIcon} />
            <CaretDownFilled className={styles.downIcon} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
