import React from 'react';
import { Outlet } from 'react-router-dom';

import { TopBar } from './components/top-bar/top-bar';
import { Config } from './models/config';
import { Footer } from './components/footer/footer';

import styles from './public-app.module.scss';

interface Props {
  config: Config | null;
}

export const PublicPage: React.FC<Props> = ({ config }) => {
  return (
    <div className={styles.container}>
      <div className={styles.app}>
        <header>
          <TopBar />
        </header>
        <main className={styles.content}>
          <Outlet />
        </main>
        <Footer config={config} />
      </div>
    </div>
  );
};
