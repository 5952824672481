import React from 'react';

import { Customer } from './models/customer';
import { Config } from './models/config';

export interface AppContextValue {
  customerDetails: Customer | null;
  config: Config | null;
  reloadCustomerDetails: (
    onResult: (customer: Customer | null) => void
  ) => void;
  reloadConfig: (onResult: (config: Config | null) => void) => void;
}

export const AppContext = React.createContext<AppContextValue>({
  customerDetails: null,
  config: null,
  reloadCustomerDetails: () => undefined,
  reloadConfig: () => undefined,
});
